import React from "react"

import { PageLayout } from "@components/layouts"
import Seo from "@components/seo"
import TextBlock from "@components/textBlock"
import SingleColumnBlock from "@components/singleColumnBlock"
import TripleColumnBlock from "@components/tripleColumnBlock"
import PageWrapper from "@components/pageWrapper"

import {
  FormWrapper,
  RequestMoreInfo,
} from "@components/Forms"

const IndexPage = () => {
  return (
    <PageLayout>
      <Seo
        title="Digital Course Creation: Turning Expertise into Income and Leads"
        ogDescription="Transform expertise into new income streams with our digital course creation service. Maximize reach and efficiency, from concept to global client engagement."
        image="/images/video/digital-course-creation.webp"
      />
      <TextBlock
        textPadded
        textLeft={
          <div>
						<h1>Digital Course Creation</h1>
						<p>
							The ultimate goal of every business owner is to create products and services that scale. Digital course creation can be a scalable option for consultants, business strategists, coaches, authors, and thought leaders. With the proper framework, a digital course can create passive income for years to come.
						</p>
						<h2>How Do You Produce Your Own Digital Course?</h2>
						<p>
							There are many excellent programs out there to teach you the steps of digital course creation. Unfortunately, the expectation of those courses is that you are going to learn to produce your own content. Or, at least, they assume that you are going to hire staff to help you do it.
						</p>
						<p>
							You already have a successful business. The success of that business is the reason you have something great to teach. But it's likely a lack of time that is the reason you haven't yet started your digital course. Should you spend years to also learn how to run a <a href="/digital-marketing-agency-atlanta/">video production team</a>? That's not the most profitable use of your time. And learning to do that production work yourself is definitely not profitable.
						</p>
          </div>
        }
        textRight={
          <div>
	            <img src="/images/video/digital-course-creation.webp" className="rounded-image" alt="Digital Courses Client Scalability - Websuasion" />
          </div>
        }
      />
			<PageWrapper color="white">
	      <TextBlock
	        textPadded
	        textLeft={
	          <div>
	            <img src="/images/video/digital-course-production-experience.webp" className="rounded-image" alt="Digital Course Production Experience - Websuasion" />
	          </div>
	        }
	        textRight={
	          <div>
							<h2>A Unique Digital Course Production Solution</h2>
							<p>
								That's where we come in. Our team has over 27 years of production experience. Our studio is in Fayetteville, GA. We're a short drive south of Hartsfield-Jackson Atlanta International Airport (ATL). Our facility is custom-designed to produce quality audio and video digital course content. In fact, we built it to create our own digital courses.
							</p>
							<p>
								Our framework makes it simple to create, launch, and relaunch successful digital courses. The goal is to make effective use of your limited time. You are the subject matter expert and talent. Everything else is our responsibility. And that goes beyond our production team. Our web development team are experts at designing and maintaining robust web platforms. We provide everything you need for your course website. <a href="/content-marketing-strategy/">Content marketing</a>, <a href="/marketing-technology/">lead funnels</a>, social media, list-building, <a href="/atlanta-video-production/webinar-production/">webinars</a>, and learning management systems. We coordinate the entire process.
							</p>
							<h2>Your Well-Oiled Digital Course Machine</h2>
							<p>
								It's fine if you only have a loose digital course concept. We'll vet your concept and investigate your market. We'll collect your knowledge and organize it into digestible modules. We'll make you comfortable with the camera and produce quality videos. We'll build up interest leading up to your course launch. We guide you through the launch. We'll manage your client interaction. And we help you to relaunch your course over and over, increasing your client base with each release.
							</p>
						</div>
	        }
	      />
			</PageWrapper>
	    <PageWrapper color="violet">
	      <SingleColumnBlock
	        textPadded
	        text={
	            <div>
								<h2>Is Our Digital Course Team The Right Fit For You?</h2>
								<p>
									We are happy to talk to you and learn more about your digital course idea. There are certain types of courses we do well, and others we do not. We'll be honest and direct with you. If your course is not in our wheelhouse, we will offer alternative suggestions. If we know we can knock it out of the park, we'll tell you that as well. Either way, it's our aim to provide you with a clear action plan.
								</p>
		            <FormWrapper
		              modal
		              buttonProps={{
		                content: "Schedule An Introductory Call",
		                size: "tiny",
		                icon: "calendar alternate outline",
		              }}
		            >
		              <RequestMoreInfo />
		            </FormWrapper>
	            </div>
	        }
	      />
	    </PageWrapper>
			<PageWrapper color="white">
	      <TextBlock
	        textPadded
	        textLeft={
	          <div>
	            <img src="/images/video/digital-courses-client-scalability.webp" className="rounded-image" alt="Digital Courses Client Scalability - Websuasion" />
	          </div>
	        }
	        textRight={
	          <div>
							<h2>Moving Beyond One-On-One Consulting</h2>
							<p>
								Do you already have a successful one-on-one teaching program? That's wonderful. But once your schedule is full, what mechanism do you have to make more money? You can raise your hourly rate. You can hire others to mirror you and take a cut of their rate. That's about it. You are selling your time, and that time is finite.
							</p>
							<h2>The Limitations Of Location-Based Events</h2>
							<p>
								The next step beyond one-on-one teaching for most consultants is group teaching. Live events and group masterminds provide an opportunity to reach more people. But they come with their own challenges. You have the venue costs. You may have food cost. You have to have enough interested clients local to the event to justify your base cost. And those clients must have schedule availability for your event. And, you have to be there to host the event. You are still selling your time, and that time is still finite.
							</p>
							<h2>Bringing Scale To Your Consultation</h2>
							<p>
								Digital Courses allow you to move away from selling your time and toward selling your value. Digital Courses are not limited by the calendar, availability, venue, and locality. But what's exciting - they can reach hundreds or even thousands of clients. You are only limited by the level of interest in the market and your ability to connect with them. What do you charge a consulting client or event attendee currently? Imagine that price times several hundred - or several thousand.
							</p>
							<h2>Launch, Tweak, Relaunch, Tweak, Relaunch</h2>
							<p>
								There are a couple of other huge benefits you get out of producing a digital course. Once we've created your course material, you can reuse it over and over again as you relaunch your course. But you also get feedback from your clients as they go through the course modules. That feedback shows us where your course needs improvement. With minimal changes, you can make your course better and better with each relaunch. Your client base will grow with each relaunch. You can relaunch the same course for years.
	          	</p>
						</div>
	        }
	      />
	      <SingleColumnBlock
	        textPadded
	        text={
	          <div>
	            <h4>Let's Talk!</h4>
	            <p>"Data. Tech. Team." Those are the words we live by at websuasion. Our services foster growth and scalability for your business - all while maintaining your brand. We are an indispensable ally for you as you navigate online marketing. Let's talk. We'd love to know more about what you do and how we can solve your pain points.</p>
	            <RequestMoreInfo />
	          </div>
	        }
	      />
			</PageWrapper>
      <TripleColumnBlock
        centered
        textPadded
        textLeft={
          <div className="team-block">
            <h3><a href="/atlanta-video-production/">YouTube Series Production</a></h3>
            <p>
              Boost your brand with long format video production. Websuasion creates engaging, optimized YouTube content that elevates visibility and credibility, maximizing ROI.
            </p>
          </div>
        }
        textCenter={
          <div className="team-block">
            <h3><a href="/atlanta-video-production/podcast-production/">Podcast Production</a></h3>
            <p>
              Websuasion crafts engaging podcasts for leaders, turning deep conversations into content that builds authority and connections.
            </p>
          </div>
        }
        textRight={
          <div className="team-block">
            <h3><a href="/atlanta-video-production/webinar-production/">Evergreen Webinars</a></h3>
            <p>
              Discover the power of evergreen webinars to educate, engage, and convert prospects to customers. Enable sales at scale without geographical and time limitations.
            </p>
          </div>
        }
      />
    </PageLayout>
  )
}

export default IndexPage
